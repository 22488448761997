div{
    // color:white;
    h2{
        font-size: xx-large;
       
    }
    p{
        font-size: small;
        // color: white;
        a{
            color: rgb(179, 179, 57);
        }
    }
    h3{
        font-size: small;
    }
    
}