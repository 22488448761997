// $a:rgba(97,121,255,1);
// $b:rgba(88,107,232,0.91);
// $c:rgba(110,127,255,1);
// $d:rgba(167,174,238,.93);
// $e:rgba(41,59,255,1);
// .products {
//   h1{
//     color: $c;
//     text-align: center;
//     width: 100%;
//     border: solid 5px $c;
//     border-radius: 10px;
//     font-size: xx-large;
//     padding: 20px;
//   }
//     display: block;
//     width: 100%;
//     height: calc(100%-5.5rem - 3.5rem);
//     padding: 0;
//     margin:0;
  
//     .productResults {
//       display: flex;
//       flex-wrap: wrap;
//       margin: 3rem -10px 0;
//     }
//   }
  
//   .product {
//     background-color: $b;
//     width: 25%;
//     margin: 0 auto 2rem;
//     padding: 10px 10px 10px 10px;
//     border-radius: 5px;
//     .thumb {
//       position: relative;
//       width: 100%;
//       margin:0 auto;
//       text-align: center;
//       display: block;
//       img {
//         width:60%;
//         margin: 0;
//         border-radius: 5px;
//       }
//     }
  
//     .details {
//       display: block;
//       width: 100%;
//       padding: 1rem 0;
//       margin: 0 auto;
  
//       ul, ul li {
//         padding: 0;
//         margin: 0;
//       }
  
//       ul {
//         li {
//           text-align: center;
//           display: block;
//           width: 100%;
//           list-style-type: none;
//           text-align: left;
//           margin: 0 0 .5rem;
  
//           a {
//             text-align: center;
//             width: 100%;
//             color: white;
//             font-weight: 900;
//           }
//           .description{
//             font-size: medium;
//             color: white;
//             font-weight: 700;
//           }
//           .name {
//             color: white;
//             font-size: x-large;
//             line-height: 1.2;
//             font-weight: 400;
//           }
  
//           .price {
//             color: white;
//             width: 100%;
//             text-align: center;
//             font-size: large;
//             line-height: 1;
//             font-weight: 600;
//           }
  
//           .addToCart {
//             margin: 2rem 0 0 0;
//             button{
//               background-color:  $c ; 
//               color: white;
//             }
//           }
//         }
//       }
  
//     }
//   }
  
//   @media only screen and (max-width: 980px) {
//     .product {
//       width: 85%;
//     }
//   }

.img {
  height: 500px;
  width: 100%;
  background-color: #f4f4f4;
}

.lote-title {
  margin-bottom: 40px;
}

.lote-details {
  padding: 15px;
  border: 1px solid rgb(80, 99, 248);
  border-radius: 10px;
}

.products-section {
  .row, .col-4 {
    margin-bottom: 40px;
  }
}

.visualize {
  margin-right: 10px;
}

.round-button {
  a {
    color: #fff !important;
    text-decoration: none;
  }
}

ul {
  list-style: none;
}

li {
  color: #000 !important;
}