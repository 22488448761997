// .homepage{
//     height: 100%;
// }

.padding-top-section {
    padding-top: 60px;
}

.how-it-works {
    margin-bottom: 60px;
}

.how-it-works-title {
    h2 {
        margin-bottom: 50px;
    }
}

.logo-branding {
    width: 200px;
}

.how-it-works-block {
    height: 100%;
    margin: 0 5px;
    text-align: left;
    border-width: 1px;
    border-style: solid;
    border-image: initial;
    padding: 16px;
    border-color: rgb(224, 224, 224);
    border-radius: 8px;
    .icon {
        font-size: 36px;
        margin-bottom: 25px;
    }
    .box-1 {
        color: #5063EE;
    }
    .box-2 {
        color: #77CCC2;
    }
    .box-3 {
        color: #FEC047;
    }
    h4 {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5rem;
    }
    p {
        display: block;
        color: rgb(117, 117, 117);
        text-align: inherit;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        margin: 0px;
    }
}

.product-section {
    .container {
        min-height: 1px;
        margin-bottom: 24px;
    }
    .btn-primary {
        background-color: rgba(255, 255, 255, 0) !important;
        color: rgb(80, 99, 248);
        min-width: 48px;
        height: 48px;
        width: 30%;
        text-transform: none;
        cursor: pointer;
        border-width: 1px;
        border-style: solid;
        border-color: rgb(80, 99, 248);
        border-image: initial;
        padding: 4px 16px;
        border-radius: 8px;
    }
}

.why-buy-section {
    margin-top: 250px;
    background-color: rgb(20, 33, 88);
    height: 400px;
    width: 100%;
    color: white;
    .container {
        width: 60%;
    }
    .row {
        width: 20%;
        button {
            margin-top: 32px;
        }
    }
}


.container-why-buy-box {
    position: absolute;
    right: 250px;
    .why-buy-img {
        transform: translate(-50%, -29%);
        width: 250px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
    .why-buy-box {
        position: relative;
        z-index: 1;
        background: rgb(255, 255, 255);
        color: #000 !important;
        width: 300px;
        transform: translate(-50%, -86%);
        padding: 16px;
        border-radius: 8px;
    }
}

.why-buy-text {
    width: 70%;
    text-align: justify;
    transform: translateY(25%);
    .why-buy-p {
        margin-top: 32px;
        margin-bottom: 32px;
        p {
            display: block;
            color: inherit;
            text-align: inherit;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5rem;
            margin: 0px 0px 8px;
        }
    }
    h1 {
        display: block;
        color: inherit;
        text-align: inherit;
        font-size: 1.5rem;
        font-feature-settings: "ss01" 0;
        font-weight: 400;
        line-height: 2rem;
        margin: 0px;
    }
}

.help-section {
    margin-top: 0;
    background-color: rgb(80, 99, 240);
    height: 200px;
    width: 100%;
    text-align: center;
    padding-top: 64px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 32px;
    h1 {
        display: block;
        color: rgb(255, 255, 255);
        text-align: inherit;
        font-size: 1.5rem;
        font-feature-settings: "ss01" 0;
        font-weight: 400;
        line-height: 2rem;
        padding-bottom: 32px;
        margin: 0px;
    }
    button {
        background-color: rgba(255, 255, 255, 0);
        color: rgb(255, 255, 255);
        min-width: 48px;
        height: 48px;
        text-transform: none;
        cursor: pointer;
        border-width: 1px;
        border-style: solid;
        border-color: rgb(255, 255, 255);
        border-image: initial;
        padding: 4px 16px;
        border-radius: 8px;
    }
}

.card-title.h5 {
    display: block;
    color: inherit;
    text-align: inherit;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0px;
    overflow: hidden;
}

.card {
    h6 {
        display: block;
        color: rgb(117, 117, 117);
        text-align: inherit;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.3125rem;
        margin: 0px;
    }
    p {
        display: inline;
        color: rgb(117, 117, 117);
        text-align: inherit;
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 1.125rem;
        margin: 0px;
    }
    h5 {
        color: rgb(117, 117, 117);
        text-align: inherit;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.3125rem;
        margin: 0px;
    }
}

.round-button {
    background-color: rgb(80, 99, 240);
    border: 1px solid rgb(80, 99, 240);
    color: white;
    padding: 10px;
    border-radius: 10px;
    transform: translateY(-100%);
    float: right;
}