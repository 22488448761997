.modalOverlay {
    width: 100%;
    height: 100%;
    background-color: rgb(54, 39, 184);
  }
  
  .modalWrap {
    width: 100%;
    height: 50%;
    pointer-events: none;
    z-index: 100;
  
    .modal {
      background-color: rgb(54, 39, 184);
      width: 95%;
      padding: 2rem;
      max-width: 80%;
      
      height: auto;
      min-height: 40.0rem;
      pointer-events: all;
      max-height: 95vh;
      overflow: auto;
    }
  }