
.margin-menu {
  margin-top: 100px;
  margin-bottom: 100px;
}

h2 {
  font-size: 1rem !important;
  margin-bottom: 50px !important;
}

aside {
  margin-bottom: 50px;
}

.nav {
  background-color: #f4f4f4;
  .nav-link {
    color: #000 !important;
    &.nav-title {
      background-color: rgb(80, 99, 248);
      color: #fff !important;
      cursor: default;
    }
  }
}

.btn-primary {
  a {
    color: #fff !important;
    text-decoration: none;
  }
}