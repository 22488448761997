
// $a:rgba(97,121,255,1);
// $b:rgba(88,107,232,0.91);
// $c:rgba(138,143,219,1);
// $d:rgba(167,174,238,.93);
// $e:rgba(41,59,255,1);
// .header {
//   height: 5.5rem;
//   box-shadow: 0 5px 10px 0 $c;
//   background-color:  $b ; 
//     position: sticky;
//   top: 0;
//   z-index: 20;
//   .wrap {
//     position: absolute;
//     height: 100%;
//     margin: 0 auto;
//     display: inline;
//     width: 100%;

//     .logo {
//       display: block;
//       width: 5.5rem;
//       margin-left: 1.5rem;
//       position: absolute;
//       top: 50%; left: 10px;
//       -webkit-transform: translateY(-50%);
//       -moz-transform: translateY(-50%);
//       transform: translateY(-50%);
      
//     a{
//       display: block;
//       img {
//        width: 100%;
//        margin: 0;
//   }
// }
    
//     }

//     nav {
//       position: relative;
//       display: inline;
//       height: 100%;
//       width: 100%;
//       margin: 10%;

//       ul, ul li {

//         display: inline-block;
//         padding: 0;
//         margin: 0;
//         height: 100%;
//       }

//       ul {
//         padding-top: 0.5rem;
//         padding-bottom: 0.5rem;
//         height: 100%;
//         text-align: center;
//         width: 25%;

//         li {

//           height: 100%;
//           padding: 0;
//           margin: 0 auto;
//           list-style-type: none;
//           a {
//             height: 100%;
//             font-size: 3.0rem;
//             text-decoration: none;
//             cursor: pointer;
//             align-items: center;
//             vertical-align: middle;
//             button{
//               font-size: large;
//               height: 100%;
//               margin: 0 auto;
//               background-color: $c;
//               color:white ;
//               transition: 0.5s;
//               box-shadow: 2px 2px 2px 2px  $b;
              
//             }:hover{
//               background-color: $a;
//               color:white ;
//               transition: 1s;
//             }
//           }:hover{
//             color: $d;
//             transition:1s;
//           }
//         }
//       }
//     }

//     .callToActions {
//       position: absolute;
//       display: inline;
//       width: 50%;
//       height: 100%;
//       text-align: end;
//       .mobileMenu {
//         display: none;
//       }
      

//       ul, li {
//         margin: 0;
//         padding: 0;
//         height: 100%;
//       }
//       ul {
//         vertical-align: middle;
//         width: 100%;
//         height: 100%;
//         text-align: end;
        
//       }

//       li {
//         padding-top: 0.5rem;
//         padding-bottom: 0.5rem;
//         a{
//           button{
//             font-size: large;
//             height: 100%;
//             margin: 0 auto;
//             background-color: $c;
//               color:white ;
//               transition: 0.5s;
//               box-shadow: 2px 2px 2px 2px  $b;
//             svg{
//               color: white ;
//             }
//           }:hover{
//             background-color: $a;
//             color:white ;
//             transition: 1s;
//           }
//         }
//         display: inline-block;
//         list-style-type: none;
//         margin-right: 0.5rem;

//         &:last-child {
//           margin-right: 0;
//         }


//       }

//     }

//   }

// }


// @media only screen and (max-width: 980px) {
//   .header {
//     position: fixed;
//   left: 0;
//   top:calc( 100% - 5.5rem);
//   width: 100%;
//     height: 5.5rem;
//     box-shadow: 0 2px 4px 0 white;
//     background-color:  $c ;
//     z-index: 0;

//     .wrap {
//       position: absolute;
//       height: 100%;
//       margin: 0 auto;
//       display: inline;
//       width: 100%;
  
//       .logo {
//         display: block;
//         width: 3.5rem;
//         margin-left: 0.5rem;
//         position: absolute;
//         top: 50%; left: 10px;
//         -webkit-transform: translateY(-50%);
//         -moz-transform: translateY(-50%);
//         transform: translateY(-50%);
        
//       a{
//         display: block;
//         img {
//          width: 100%;
//          margin: 0;
//     }
//   }
      
//       }
  
//       nav {
//         display: inline-block;
//         position: relative;
//         display: inline;
//         height: 100%;
  
//         margin: 15%;
//         ul, ul li {

//           display: inline-block;
//           padding: 0;
//           margin: 0;
//           height: 100%;
//         }
  
//         ul {
//           padding-top: 0.1rem;
//           padding-bottom: 0.1rem;
//           height: 100%;
//           text-align: center;
//           width: 5%;
  
//           li {
  
//             height: 100%;
//             padding: 0;
//             margin: 0 auto;
//             list-style-type: none;
//             a {
//               height: 100%;
//               font-size: 3.0rem;
//               text-decoration: none;
//               cursor: pointer;
//               align-items: center;
//               vertical-align: middle;
//               button{
                
//                 font-size:1.5rem;;
//                 height: 100%;
//                 margin: 0 auto;
//                 background-color: $c;
//                 color:white ;
//                 transition: 0.5s;
//                 box-shadow: 2px 2px 2px 2px  $b;
//               }:hover{
//                 background-color: $a;
//                 color:white ;
//                 transition: 1s;
//               }
//             }:hover{
//               color: $d;
//               transition:1s;
//             }
//           }
//         }
//       }
  
//       .callToActions {
//         position: absolute;
//       display: inline-block;
//       width: 60%;
//       height: 100%;
//       text-align: center;
//       .mobileMenu {
//         display: none;
//       }
      

//       ul, li {
//         margin: 0;
//         padding: 0;
//         height: 100%;
//       }
//       ul {
//         vertical-align: middle;
//         width: 100%;
//         height: 100%;
//         text-align: end;
        
//       }

//       li {
//         padding-top: 0.1rem;
//         padding-bottom: 0.1rem;
//         a{

//             button{
//               display: block;
//               text-align: center;
//               align-content: center;

//               font-size:1rem;
//               height: 100%;
//               margin: 0 auto;
//               background-color: $c;
//               color:white ;
//               transition: 0.5s;
//               box-shadow: 1px 1px 1px 1px  $b;
//             }:hover{
//               background-color: $a;
//               color:white ;
//               transition: 1s;
//             }
//           }
//           list-style-type: none;
//           margin-right: 0.5rem;
  
//           &:last-child {
//             margin-right: 0;
//           }
  
  
//         }
  
//       }
  
//     }
  
//   }
  
// }

.nav-transparent {
    background-color: rgb(80, 99, 240);
    color: white;
    box-shadow: 0px 3px 9px 1px rgba(0,0,0,0.3);
    position: fixed !important;
    width: 100%;
    top: 0;
    z-index: 100;
    // border-bottom: 1px solid rgba(0, 0, 0, .6);
}


.navbar-brand, .nav-link {
    color: white !important;
    font-weight: 600;
}