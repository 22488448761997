// $a:rgba(97,121,255,1);
// $b:rgba(88,107,232,0.91);
// $c:rgba(110,127,255,1);
// $d:rgba(167,174,238,.93);
// $e:rgba(41,59,255,1);
// $f:rgba(243, 227, 136, 0.88);
// .directory {
//     height: 100%;
//     .title{
//       padding: 10px;
      
//       color:$c;
//         text-align: center;
//         font-size: large;
//         background-color: white;
//         border: solid 2px $c;
//         border-radius: 20px;
//     }
//     padding: 10px 10px 10px 10px;
//     .wrap {
//       display: inline-block;
//       width: 100%;
//       height: 120%;
//       padding: 10px 10px 4rem 10px;
  
//       .item {
//         text-align: center;
//         padding: 10px 10px 10px 10px;
//         position: relative;
//         width: 50%;
//         float: left;
//         background-position: center;
//         background-repeat: no-repeat;
//         background-size: cover;
//         h1{
//           color: white;
//           border: solid 2px $c;
//           border-radius: 15px;
//           background-color: $c;
//           padding: 10px 10px 10px 10px;
//         }
//         .destaques{
//           position: relative;
//           height: 80%;
//           overflow-y: scroll;
//           background-color: $c;
//           border-radius: 10px;
//           border: solid 10px $c;
//         }
//         &::after {
//           content: '';
//           position: absolute;
//           top: 0; left: 0;
//           width: 100%;
//           height: 100%;
//           z-index: -1;
//         }
//         .lote{width: 100%;}
  
        
      
  
//       }
//     }
  
//   }
  
//   @media only screen and (max-width: 980px) {
  
//     .directory .wrap .item {
//       width: 100%;
//       height: 50%;
  
//       a {
//         font-size: 1.5rem;
//       }
//     }
  
//   }

.margin-menu {
    margin-top: 40px;
}

.padding-top {
    padding-top: 30px;
}

.super-padding-top-section {
    margin-top: 100px;
}

.padding-top-less {
    padding-top: 20px;
}

.header {
    h2 {
        display: block;
        color: inherit;
        text-align: inherit;
        font-size: 2.125rem;
        font-weight: 500;
        line-height: 2.5rem;
        margin: 0px;
    }
    h3 {
        display: block;
        color: rgb(117, 117, 117);
        text-align: inherit;
        font-size: 1.5rem;
        font-feature-settings: "ss01" 0;
        font-weight: 400;
        line-height: 2rem;
        margin: 0px;
    }
}

.text-header {
    width: 25%;
    margin: 60px auto;
    h4 {
        display: block;
        color: inherit;
        text-align: inherit;
        font-size: 1.5rem;
        font-feature-settings: "ss01" 0;
        font-weight: 400;
        line-height: 2rem;
        margin: 0px 0px 8px;
    }
    p {
        display: block;
        color: rgb(117, 117, 117);
        text-align: inherit;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        margin: 0px 0px 8px;
    }
    .link {
        color: rgb(80, 99, 248);
        font-family: inherit;
        text-decoration: none;
    }
}

.form-container {
    border: 1px solid rgb(224, 224, 224);
    width: 40%;
    margin: 0 auto;
    border-radius: 20px;

    label {
        padding-left: 10px;
        font-size: 12px;
        margin-bottom: -20px;
        color: rgba(0, 0, 0, 0.54);
    }

    .css-1s2u09g-control {
        border: none;
    }

    .border-bottom {
        border-bottom:  1px solid rgb(224, 224, 224);
    }

    .border-right {
        border-left:  1px solid rgb(224, 224, 224);
    }

    .border-left {
        border-left:  1px solid rgb(224, 224, 224);
    }

    input, select {        
        &:not([type="checkbox"]) {
            border: none;
            &:active {
                box-shadow: none;
            }
        }
    }
}

.btn-primary {
    background-color: #5063F0 !important;
}