$a:rgba(97,121,255,1);
$b:rgba(88,107,232,0.91);
$c:rgba(110,127,255,1);
$d:rgba(167,174,238,.93);
$e:rgba(41,59,255,1);
        
.formrow{
    .labelInput{
        color: $d;    }
    ::placeholder{
        color: $d;
        font-weight: 900;
    }
    input{
        display: block;
        width: 100%;
        padding: 10px 5px;
        margin: 10px auto;
        line-height: 1;
        font-weight: 500;
        border-radius: 5px;
     
        border: solid 2px $d;
    }

}