.verticalNav {
    width: 100%;
    height: 100%;
    border: solid 1px #2b2a2a ;
  
    .menu {
      margin: 3rem auto 0;
  
      ul, li {
        margin: 0;
        padding: 0;
        list-style-type: none;
      }
  
      ul {
  
        li {
          width: 100%;
          border-bottom: 1px solid #2b2a2a;
  
          &:first-child {
            border-top: 1px solid #2b2a2a;
          }
  
          a, span {
            display: block;
            width: 100%;
            padding: 2rem;
            font-size: 1.6rem;
            line-height: 1;
            color: rgb(255, 255, 255);
            cursor: pointer;
          }
  
          &:hover {
            background-color: rgb(54, 39, 184);
          }
        }
  
      }
    }
  }