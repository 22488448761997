
// .productCard {
//   text-align: center;
//     max-width: 80%;
//     margin: 0 auto 10.0rem;
  
//     .hero {
//       text-align: center;
//       display: block;
//       width: 100%;
//       margin: 2rem auto;
  
//       img {
//         display: block;
//         width: 100%;
//         margin: 0;
//       }
//     }
  
//     .productDetails {
//       text-align: center;
//       ul, ul li {
//         padding: 0;
//         margin: 0;
//       }
  
//        ul li {
//          list-style-type: none;
//          margin: 0 auto 1rem;
  
//          h1 {
//            margin: 0;
//          }
//        }
//     }
  
//     .addToCart {
//       width: 100%;
//     }
//   }

.img {
  height: 500px;
  width: 100%;
  background-color: #f4f4f4;
}

.lote-title {
  margin-bottom: 40px;
}

.lote-details {
  padding: 15px;
  border: 1px solid rgb(80, 99, 248);
  border-radius: 10px;
}

.products-section {
  .row {
    margin-bottom: 40px;
  }
}

.visualize {
  margin-right: 10px;
}

.round-button {
  a {
    color: #fff !important;
    text-decoration: none;
  }
}