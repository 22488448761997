// $a:rgba(97,121,255,1);
// $b:rgba(88,107,232,0.91);
// $c:rgba(110,127,255,1);
// $d:rgba(167,174,238,.93);
// $e:rgba(41,59,255,1);
        
// .signin{
//     display: block;
//     width: 100%;
//     max-width: 15.0rem;
//     border: 1px solid white;
//     margin: 4rem auto 6rem;
    
//         .formwrap{
//             form{
//                 #btn{
//                     color:white ;
//                     font-weight: 1000;
//                     background-color:$c;
//                 }
//             }
//         }

//     }


.margin-menu {
    margin-bottom: 100px;
}

.login-container {
    height: 450px;
}