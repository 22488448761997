.adminToolbar {
    display: inline-block;
    width: 100%;
    background-color:  rgba(54, 39, 184, 0.842);
    height: auto;
    margin: 0 auto;
    padding: 0 10px;
  
    ul, li {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
  
    ul {
      float: right;
  
      li {
        display: inline-block;
  
        a {
          display: block;
          font-size: 1.6rem;
          line-height: 1;
          color: white;
          padding: 10px;
          transition: all .4s ease-in-out;
  
          &:hover {
            background-color: rgba(255,255,255,.6);
            color: black;
            transition: all .4s ease-in-out;
          }
  
        }
      }
    }
  
  }
  