
.btn{
    margin: 0 auto;
    padding: 0px;
    width: 100%;
    display:inline-block;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    font-size: small;
    background-color: rgb(197, 23, 23);
    color: white;
    border-radius: 15px;
    border: none;
    padding: 10px;
    cursor: pointer;
    div{
        padding: 5px 5px;
        // text-transform: lowercase;
        font-weight: 1000;
        svg{
            margin-right: 10px;
        }

    }
    

}