// $a:rgba(97,121,255,1);
// $b:rgba(88,107,232,0.91);
// $c:rgba(110,127,255,1);
// $d:rgba(167,174,238,.93);
// $e:rgba(41,59,255,1);


//   .lotes {
//   position: relative;
//   background-color: white;
//   color: $e;
//   display: block;
//   width: 100%;
//   height: 100%;
//   padding: 50px;
//   margin: 0 auto;
//   text-align: center;
//     h1{
//       color: $c;
//       text-align: center;
//       width: 100%;
//       border: solid 5px $c;
//       border-radius: 10px;
//       font-size: xx-large;
//       padding: 20px;
//     }
//   .loteResults {
//     position: relative;
//     width: 100%;
//     height: 100%;
//     margin: 3rem -10px 0;
//     text-align: center;
//     align-items: center;
//     a{
//       position: relative;
//       display: inline-table;
//       width: 25%;
//       margin: 2rem;
//       .lote {
//         border: solid 5px $e;
//         margin: 2rem;
//         position: relative;
//       text-transform: uppercase;
//       background-color:  $c ;
//       width: 100%;
//       height: 100%;
//       margin: 0 auto 2rem;
//       padding: 10px 10px 10px 10px;
//       border-radius: 5px;
//       color: $e;
//       .thumb {
//         position: relative;
//         width: 100%;
//         margin:0 auto;
//         text-align: center;
//         display: block;
//       }
    
//       .details {
//         text-align: center;
//         font-size: small;
//         display: block;
//         width: 100%;
//         padding: 1rem 0;
//         margin: 0 auto;
//         color: $e;
//         ul, ul li {
//           text-align: center;
//           padding: 0;
//           margin: 0;
//         }
    
//         ul {
//           text-align: center;
//           li {
//             text-align: center;
//             display: block;
//             width: 100%;
//             list-style-type: none;
//             margin: 0 0 .5rem;
    
//             a {
    
//               text-align: center;
//               width: 100%;
//               color: $e;
//             }
//             .edital{
//               line-height: 5;
//               font-weight: 900;
//               text-align: center;
//               color: $e;
//               color:white;

//             }
//             .cidade {
//               text-align: center;
//               color:white;
//               font-size: small;
//               line-height: 1.2;
//               font-weight: 400;
//             }
    
//             .tipo {
//               text-align: center;
//               color:white;
//               width: 100%;
//               text-align: center;
//               font-size: small;
//               line-height: 1;
//               font-weight: 400;
//             }
//             .destaque{
//               text-align: center;
//               text-transform: uppercase;
//               color: rgb(255, 255, 255);
//               background-color: green;
//               margin: 2px 2px 2px 2px;
//               padding:  2px 2px 2px 2px;
//               border-radius: 2px;
//             }
    
//             .addToCart {
//               margin: 2rem 0 0 0;
//             }
//           }
//         }
    
//       }
//     }
//     }
//   }
// }


// @media only screen and (max-width: 980px) {
//   .lote {
//     width: 100%;
//   }
// }



.margin-menu {
  margin-top: 100px;
  margin-bottom: 100px;
}

h2 {
  font-size: 1rem !important;
  margin-bottom: 50px !important;
}

aside {
  margin-bottom: 50px;
}

.nav {
  background-color: #f4f4f4;
  .nav-link {
    color: #000 !important;
    &.nav-title {
      background-color: rgb(80, 99, 248);
      color: #fff !important;
      cursor: default;
    }
  }
}

.margin-bottom {
  margin-bottom: 50px;
}

.btn-primary {
  a {
    color: #fff !important;
    text-decoration: none;
  }
}

.products-section {
  .col-4 {
    margin-bottom: 40px;
  }
}