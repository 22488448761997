// $a:rgba(97,121,255,1);
// $b:rgba(88,107,232,0.91);
// $c:rgba(110,127,255,1);
// $d:rgba(167,174,238,.93);
// $e:rgba(41,59,255,1);
        
//         .formwrap{
//             form{
//                 h2{
//                     color: $c;
//                 }
//                 #btn{
//                     color: rgb(255, 255, 255);
//                     font-weight: 1000;
//                     background-color:$c;
//                     border: solid 2px white;
//                 }
//                 #btnCadastro{
//                     margin-top: 1rem;
//                     color: $c;
//                     font-weight: 1000;
//                     background-color: $e;
//                     border: solid 2px white;
//                 }
//                 .links {
//                     text-align: center;
//                     align-items: center;
//                     display: inline-block;
//                     width: 100%;
//                     margin:0 auto;
//                     padding: 0.5rem;
                
                  
//                     a {
//                         display: inline;
//                         color: $c;
//                         font-size: large;
//                         padding: 10px;
//                         font-weight: 900;
//                     }:hover{
//                         color: rgb(168, 170, 199);
//                     }
//                 }
//             }
//         }
//         .socialsignin{
//             margin :1.5rem auto 0; 
//             .row{
//                 .btn{
//                     color: antiquewhite;
//                     background-color: rgb(160, 34, 34);
//                 }
//             }
//         }


.margin-menu {
    margin-bottom: 100px;
}

.login-container {
    width: 30% !important;
    margin: 0 auto;
    height: 900px;
    img {
        margin-bottom: 50px;
    }
}

.margin-top-button {
    margin-top: 10px;
}

.link-password {
    text-decoration: none;
    color: #5063f0;
}