@import "~bootstrap/scss/bootstrap";

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

// $a:rgba(97,121,255,1);
// $b:rgba(88,107,232,0.91);
// $c:rgba(110,127,255,1);
// $d:rgba(167,174,238,.93);
// $e:rgba(41,59,255,1);
// $f:white;
// html{
//     font-size:10px ;
// }
// html,
// body{
//     margin: 0%;
//     padding: 0%;
//     font-family: 'Oswald', sans-serif;;
//     font-weight: 100;
//     line-height: 1;
//     background-color: white;
// }
// html,
// body,
// #root,
// .App,
// .fullHeight,
// .main
// {
//     height: 100%;
// }
// *,::before,::after{
//     -webkit-box-sizing: border-box;
//     -moz-box-sizing: border-box;
//     box-sizing: border-box;
// }
// a{
//     text-decoration: none;
// }
// a, a:hover{
//     -webkit-transition: all .4s ease-in-out;
//     -moz-transition: all .4s ease-in-out;
//     transition: all .4s ease-in-out;
// }
// .main{
//     max-width: 100%;
//     height: 100%;
//     padding: 0 auto;
//     margin: 0 auto;
//     height: calc(100% - 5.5rem - 3.5rem);
// }
// .controlPanel {
//     color: $e;
//     position: relative;
//     display: inline-block;
//     width: 100%;
//     min-height: 100%;
//     padding: 3rem 0 6rem 25.0rem;
//     border-bottom: 1px solid #2b2a2a;
  
//     .sidebar {
//       position: absolute;
//       top: 0; left: 0;
//       width: 25.0rem;
//       height: 100%;
//       z-index: 1;
//       border-right: 1px solid #2b2a2a;
//     }
  
//     .content {
//       padding: 0 10px;
//     }
//   }
  
//   .adminLayout {
//     height: calc(100% - 6.5rem - 5.8rem - 3.6rem);
//   }
  
//   .dashboardLayout {
//     height: calc(100% - 5.5rem - 3.5rem);
//   }
  
//   @media only screen and (max-width: 980px) {
//     html {
//       font-size: 9px;
//     }
  
//     .controlPanel {
//       padding: 0 10px;
  
//       .sidebar {
//         position: relative;
//         width: 100%;
//         border: none;
//         margin-bottom: 2.5rem;
//       }
//     }
//   }