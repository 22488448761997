.paymentDetails {
    display: block;
    width: 100%;
    max-width: 100.0rem;
    padding: 0;
    margin: 2rem auto;
    border:  solid 1px white;
    color: blanchedalmond;
  
    .group {
      color: blanchedalmond;
      margin: 0 0 2rem;
      border:  solid 1px white;
    }
  
  }

  table {
    .link {
      text-decoration: none;
      color: #5063f0;
    }
  }