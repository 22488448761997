
// $a:rgba(97,121,255,1);
// $b:rgba(88,107,232,0.91);
// $c:rgba(110,127,255,1);
// $d:rgba(167,174,238,.93);
// $e:rgba(41,59,255,1);
// .checkout {
//   position: relative;
//     margin: 2rem auto;
//     height: 100%
//     h1 {
//       display: block;
//       width: 100%;
//       color: $c;
//     }
  
//     h1, p {
//       color: $c;
//       text-align: center;
//     }
  
//     .checkoutHeader {
//       color: $c;
//       border-bottom: 5px solid $b;
//     }
  
//     .cart {
//       color: $c;
//       max-width: 100.0rem;
//       margin: 0 auto;
  
//       table {
//         color: $c;
//         width: 100%;
//       }
//     }
  
//     .checkoutHeader,
//     .cart {
//       color: $c;
//       width: 100%;
//       text-align: left;
  
//       th,
//       td {
//         color: $c;
//         width: 22%;
//         .btn{
//           background-color:$c;
//         }
//         .cartBtns {
//           color: $c;
//           display: block;
//           width: 100%;
//         }
      
//         .cartBtn {
//           color: $c;
//           cursor: pointer;
//         }
      
//       }
//     }
  
//     .cartItem {
//       color: $c;
//       td img {
//         color: azure;
//         display: block;
//         width: 100%;
//       }
//     }
  
   
//   }
  
//   @media only screen and (max-width: 980px) {
//     .checkout{
//       padding-bottom: 3.5rem;
//     }
  
//     .checkoutHeader {
//       color: azure;
//       display: none;
//     }
  
//     .cartItem {
//       color: azure;
//       border: 1px solid black;
//     }
  
//     .checkout .cart td {
//       color: azure;
//       display: block;
//       width: 100%;
//     }
  
//     .checkout .cartBtn.remove {
//       display: block;
//       width: 100%;
//       padding: 1.0rem;
//       background-color:  rgb(32, 20, 139);
//       color: white;
//     }
//   }


.img-cart {
  height: 100px;
}

.text-right {
  text-align: right !important;
}

.btn-default {
  background-color: #f4f4f4 !important;
}

.cart-img {
  height: 100px;
}