.footer {
    margin-top: 0;
    background-color: rgb(244, 244, 244);
    padding: 16px 24px;
    img {
        width: 150px;
    }

    .margin-top {
        margin-top: 20px;
    }
    
    a {
        text-decoration: none;
        font-weight: bold;
        color: black;
    }

    h2 {
        margin-bottom: 20px !important;
    }
}