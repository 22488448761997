// $a:rgba(97,121,255,1);
// $b:rgba(88,107,232,0.91);
// $c:rgba(110,127,255,1);
// $d:rgba(167,174,238,.93);
// $e:rgba(41,59,255,1);
        
//         ul{
//             li{
//                 color: rgb(255, 255, 255);
//                 font-size: small;
//             }
//         }
//         .formwrap{
//             margin :1.5rem auto 0; 
//             form{
//                 #register{
//                     color: white;
//                     font-weight: 1000;
//                     background-color: $b;
//                 }
//             }
//             .socialsignin{
//                 margin :1.5rem auto 0; 
//                 .row{
//                     .btn{
//                         color: antiquewhite;
//                         background-color: rgb(160, 34, 34);
//                     }
//                 }
//             }
//         }

.login-container {
    height: 900px !important;
}